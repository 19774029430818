.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  
}

.App-header {
  
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.sexygradient{
  background: linear-gradient(90deg, #FFB9B9 0%,#FFB9B9 50%,#91D8DB 50%, #91D8DB 100%);
  /* background: linear-gradient(90deg, #f9bebe		 0%,#f9bebe 50%,#9cd7da 50%, #9cd7da 100%); */
  width:100%;
  height: 100%;
  display:flex;
  flex-direction: column;     
}
.notThatSexyGradient{
  background: white
}
